<template>
<div class="popup-container">
    <transition v-for="popup in popups" :key="popup._id">
        <popup-view :popup="popup"></popup-view>
    </transition>
</div>
</template>

<script>
import api from "@/api";
import PopupView from "./popup-view.vue";

export default {
    components: {
        PopupView,
    },
    props: {
        skin: { type: String, default: "basic" }
    },
    data(){
        return {
            popups: []
        }
    },
	mounted() {
		this.init();
	},
	methods: {
		async init() {
            var { popups } = await api.v1.common.popups.gets();
            this.popups = popups;
		},
	},
}
</script>

<style scoped>
</style>