var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "popup-container"
  }, _vm._l(_vm.popups, function (popup) {
    return _c('transition', {
      key: popup._id
    }, [_c('popup-view', {
      attrs: {
        "popup": popup
      }
    })], 1);
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }