<template>
<v-responsive width="100%" height="auto" max-width="100vw" >
    <component :is="template">
        <!-- 메인 공통 내용만 넣으세요 -->
        <!-- 개별적 내용은 /src/templates 폴더에 해당 프로젝트 페이지에 작업해주세요  -->
    </component>

    <popup-layer />
</v-responsive>
</template>

<script>
import PopupLayer from "@/components/client/popup/popup-layer.vue";

export default {
    components: {
        PopupLayer
    },
    computed: {
        template(){
            return () => import(`@/templates/${this.$theme.pages.main}`);
        },
    }
}
</script>