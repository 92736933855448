<template>
    <div v-if="popup && show" class="popup-layer-container" :style="popupStyle">
        <a class="popup-layer-body" :href="popup.url" target="_blank">
            <div class="popup-layer-content" v-html="popup.content.replace(/\n/g, '<br>')"></div>
        </a>
        <div class="popup-layer-foot">
            <label class="check check-light">
                <input v-model="doNotOpenToday" class="input" type="checkbox" :value="true"/>
                <i class="icon"></i>
                <span class="text">오늘하루 열지않음</span>
            </label>
            <button class="button" type="button" @click="close">
                <span class="text">닫기</span>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        popup: { type: Object, default: null }
    },
    data() {
        return {
            doNotOpenToday: false,
            show: false,
        };
    },
	mounted() {
		this.show = !this.$cookies.get(this.popup?._id);
	},
	methods : {
        close(){
            if(this.doNotOpenToday){
                this.$cookies.set(this.popup._id, "hidden", "1d");
            }
            this.show = false;
        }
	},
    computed: {
        popupStyle(){
            return {
                left: this.popup.left + 'px',
                top: this.popup.top + 'px',
                width: this.popup.width + 'px',
                height: this.popup.height + 'px'
            }
        }
    }
}
</script>
<style scoped>
    img{ max-width: 100%; }
    .popup-layer-container {
        position: absolute;
        z-index: 10000;
        background: #fff; box-shadow: 0px 0px 20px 0px rgba(0,0,0,.25); animation: popup-fade .35s ease-out .35s forwards; opacity: 0;
        min-width: 200px;
        margin-bottom: 40px;
    }
    .popup-layer-body {
        display: block;
        width: 100%;
        height: 100%;
        background: #fff;
    }
    .popup-layer-content {
        font-size: 15px;
    }
    .popup-layer-foot {
        position: absolute;
        bottom: -40px;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 40px;
        padding: 10px;
        background: #424242;
    }
    .popup-layer-foot .check { cursor: pointer; }
    .popup-layer-foot .check .text,
    .popup-layer-foot .button { padding: 0; font-size: 14px; color: #ffffff; cursor: pointer; }
    @-webkit-keyframes popup-fade{
        0% { opacity: 0; -webkit-transform: translateY(25px); transform: translateY(25px); }
        100% { opacity: 1; -webkit-transform: translateY(0); transform: translateY(0); }
    }
    @keyframes popup-fade{
        0% { opacity: 0; -webkit-transform: translateY(25px); transform: translateY(25px); }
        100% { opacity: 1; -webkit-transform: translateY(0); transform: translateY(0); }
    }
    @media(min-width:1025px){
        .popup-layer-foot .check:hover .text,
        .popup-layer-foot .button:hover { opacity: .7; }
    }
    @media (max-width: 768px){
        .popup-layer-container {
            position: absolute; top: 10px !important; left: 10px !important; right: 10px;  width: calc(100% - 20px) !important; height: fit-content !important; bottom: 10px;
            min-width: auto;
        }
        .popup-layer-content { font-size: 13px; }
        .popup-layer-content img { height: auto !important; }
        .popup-layer-foot .check .text,
        .popup-layer-foot .button { font-size: 13px; }
    }
</style>
