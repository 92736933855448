var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-responsive', {
    attrs: {
      "width": "100%",
      "height": "auto",
      "max-width": "100vw"
    }
  }, [_c(_vm.template, {
    tag: "component"
  }), _c('popup-layer')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }